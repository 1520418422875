import React, { useState,useRef, useEffect } from 'react';
import { useCompose } from '@context/compose';
import { useView } from '@context/view';
import { useMessages } from '@context/messages';
import { useLang } from '@context/lang';
import { useMailBoxes } from '@context/mailboxes';
import CancelDialog from '@components/Compose/CancelDialog';
import ContactDisplay from '@components/ContactDisplay/ContactDisplay';
import LabeledContentRow from '@components/LabeledContentRow/LabeledContentRow';
import SendButton from './SendButton';
import Field from './Fields/Field';
import FieldErrors from './Fields/FieldErrors';
import Recipients from './Recipients';
import './Compose.scss';
import { useMessageDraft } from '@context/messageDraft';
import { useAnalytics } from '@context/analytics';
import Loader from '../Loaders/loader';
import { useFieldErrors } from '@context/fieldErrors';
import { useConfig } from '@context/config';
import moment from 'moment';

const Reply = () => {
    const fieldRef = useRef([]);
    const errorListToggleRef = useRef(null);
    const editorRef = useRef(null);
    const { setThread, setFolder, setManager } = useView();
    const { allowDrafts, showMessageHistory } = useConfig();
    const { status, formData } = useCompose();
	const { loadingMailboxes, mailBoxes } = useMailBoxes();
    const { messages, setMessages, getMessages } = useMessages();
    const { fieldErrors, setFieldErrors } = useFieldErrors();
    const { dict } = useLang();
    const { messageContext, setMessageContext, sendReplyMessage, currentSenderMailbox, setCurrentSenderMailbox } = useMessageDraft();
    const { setTopicId, setSubject, setMessageBody, setCustomFields } = useMessageDraft();
    const { viewStart, viewEnd, publish } = useAnalytics();
    const [isCanceling, setIsCanceling] = useState(false);
    const [toRecipients, setToRecipients] = useState([]);
    const [heading, setHeading] = useState('');
	const sender = messageContext?.sender;
    
    useEffect(() => {
        if (!loadingMailboxes) {
            initializeReplyView();
        }
    }, [loadingMailboxes])

    useEffect(() => {
        console.log('in useEffect')
        if (fieldRef && messageContext && messageContext?.draft) {
            console.log('messageContext:', messageContext);
            console.log('fieldrefs: ' , fieldRef.current.length);
            fieldRef.current.forEach(ref => {
                console.log(ref);
            });
        }
    }, [fieldRef.current.length])

    const initializeReplyView = () => {
        viewStart('reply');
        publish({resource: 'reply'});
        // get the recipients
        const findSenderMailbox = (currentMailboxes, mid) => {
            if (mid) {
              return currentMailboxes.find(function (mailbox) {
                return mailbox.mid === mid
              })
            } else {
              return {}
            }
        }
        
        let toRecipients = [];
        if(mailBoxes.length > 0 && mailBoxes.some(mailbox => mailbox.mid === sender.mid)) {
            toRecipients = messageContext?.recipients?.recipient;
            setCurrentSenderMailbox(sender);
        } else {
            toRecipients = [sender];
            setCurrentSenderMailbox(findSenderMailbox(mailBoxes, messageContext?.mid));
        }
        if (toRecipients[0]) {
          toRecipients[0].recipient_type = 'to'
        }
        setToRecipients(toRecipients);
        setHeading(`${dict.replyTo.label}: [${toRecipients.map(el => el.name).join(', ')}]`);
        viewEnd('reply');
    }

	const cancelDialogCancelHandler = () => {
        publish({resource: 'compose', type: 'modal stay'});
        setIsCanceling(false);
    }

    const cancelDialogContinueHandler = () => {
        publish({resource: 'compose', type: 'modal leave'});
        setFolder('inbox');
        setMessages(null);
        setThread(null);
        setTopicId('');
        setSubject('');
        setMessageBody(null);
        setCustomFields([]);
        setMessageContext({});
        fieldRef.current.forEach(ref => {
            //console.log(ref);
            if (ref.field.name === 'subject') {
                ref.setFieldValue('');
            } else if (ref.field.name === 'body') {
                ref.setFieldValue('');
            } else if (ref.field.name === 'attachments') {
                // do nothing
            } else {
                ref.setFieldValue('');
            }
        });
        setManager('browse');
    }

    const onCancelHandler = () => {
        setIsCanceling(true);
    }

    const sendEditorBody = (body) => {
        editorRef.current = body;
    }
	
    const onSubmitHandler = (draft = false) => {
		let fieldErrs = getFieldErrors(formData.reply.fields);
		if (fieldErrs.length === 0) {
            sendReplyMessage(toRecipients, draft);
            getMessages(messageContext.thread_id);
        } else {
            setFieldErrors(fieldErrs);
        }
    }
    
	const getFieldErrors = (flds) => {
        let fieldErrs = [];
        flds.forEach((field, idx) => {
            const ref = fieldRef.current[idx];
            const invalidFields = ref.validateField(field);
            if (invalidFields.length > 0) {
                invalidFields.forEach(invalidField => {
                    if (!invalidField.ref) {
                        invalidField.ref = ref;
                    }
                })
                fieldErrs = fieldErrs.concat(invalidFields);
            }
        })
        return fieldErrs;
    }

	return(
		<div>
			<Loader loading={loadingMailboxes}>
            <section role="dialog" aria-labelledby="title" className='oms--compose-section'>
				{isCanceling && <CancelDialog onCancel={cancelDialogCancelHandler} onContinue={cancelDialogContinueHandler} />}
                <div>
                    <form onSubmit={e => e.preventDefault()} name='message' className='oms--compose-panel'>
                        
                        <div className='oms--body'>
                            {status === 'loaded' &&
                            <>
                                {formData.replyBanner && <div className='oms--body-title'>{formData.replyBanner}</div>}
                                {showMessageHistory && messages && messages.data && messages.data.message && 
                                    <>
                                        <div className='oms--replyDetails'>
                                            <label className='oms-replyMessage'>{dict.messageLabel.label}</label>
                                            <div className='oms--replyDetailsBody'>
                                                {messages.data.message.map((message, idx) => 
                                                    <div key={idx}>
                                                        <div className='oms--replyMessageHeader'>
                                                            <label>{dict.fromLabel.label} </label><span>{message.sender.name}</span>
                                                        </div>
                                                        <div className='oms--replyMessageHeader'>
                                                            <label>{dict.toLabel.label} </label><span>{message.recipients.recipient.map(r => r.name)}</span>
                                                        </div>
                                                        <div className='oms--replyMessageHeader'>
                                                            <label>{dict.dateLabel.label} </label><span>{moment(message.date_created).format('MMM D, YYYY h:mm a')}</span>
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{__html: message.body}}></div>
                                                        {idx < messages.data.message.length-1 && <hr/>}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='oms--div-hr'></div>
                                    </>
                                }				
                                <strong id="title" aria-label={heading} className='oms--reply-title'>
                                    {heading}
                                </strong>
								<div className='oms--replyDetails'>
									<LabeledContentRow
									  label={dict.fromLabel.label}
									>
                                        {currentSenderMailbox &&
                                            <ContactDisplay
                                                {...currentSenderMailbox}
                                                onReply='true'
                                            />
                                        }
									</LabeledContentRow>
                                    {toRecipients.length > 0} {
									    <Recipients
									        recipients={toRecipients}
									    />
                                    }
								</div>
                                {fieldErrors.length > 0 && <FieldErrors errorListToggleRef={errorListToggleRef} />}
                                <div
                                    className='oms--body-title'
                                    dangerouslySetInnerHTML={{
                                    __html: dict.asteriskMessage.label
                                    }}
                                ></div>
                                {formData.reply.fields.map((field, idx) => (<Field 
										key={idx} 
										index={idx+'-'+field.name}
                                        field={field}
										ref={(element) => {fieldRef.current[idx] = element}}
										sendEditorBody={sendEditorBody}
                                        isReply={true}
										subject={messages.data.message[0].subject}
									/>))
								}
                            </>
                            }
                        </div>
                        <div className='oms--div-hr'></div>
                        <div className='oms--footer'>
                            <SendButton
                                id='send-button'
                                className='oms--primary'
                                onClick={() => onSubmitHandler(false)}
                                aria-label={dict.sendLabel.ally}
                            />
                            {allowDrafts &&
                                <button
                                    id='drafts-button'
                                    className='oms--secondary'
                                    onClick={() => {onSubmitHandler(true)}}
                                    tabIndex='0'
                                    aria-label={dict.saveLabel.ally}
                                >{dict.saveLabel.label}</button>
                            }
                            <button
                                id='cancel-button'
                                emphasis='low'
                                className='oms--secondary'
                                onClick={onCancelHandler}
                                aria-label={dict.cancelLabel.ally}
                            >
                                {dict.cancelLabel.label}
                            </button>
                            
                        </div>
                    </form>
                </div>
            </section>
            </Loader>
        </div>
	);
}

export default Reply;
