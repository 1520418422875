import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from '@util/axios_config';
import { ConfigProvider } from '@context/config';
import { LangProvider } from '@context/lang';
import { AnalyticsProvider } from '@context/analytics';
import Widget from '@components/Widget';

import '../polyfills/document.customEvent'

const initialize = ({
  token,
  brand,
  language,
  rootElement,
  smConfig,
}) => {
  // create the config map for the config context provider
  const configMap = {
    token, 
    brand, 
    language, 
    viewManager: smConfig?.viewManager,
    portal: smConfig?.portal,
    allowCompose: smConfig?.allowCompose ?? true,
    allowReply: smConfig?.allowReply ?? true,
    hideFilter: smConfig?.hideFilter ?? false,
    showMessageHistory: smConfig?.showMessageHistory ?? false,
    allowDrafts: smConfig?.allowDrafts ?? false,
    allowPrint: smConfig?.allowPrint ?? false,
    messageMetadata: smConfig?.messageMetadata,
    topicId: smConfig?.topicId,
  }
  window.secureMessageConfig = () => {
    console.log(configMap);
  }
  // Set the axios configs
  if (token) axios.defaults.headers.Authorization = `Bearer ${token}`
  if (language) axios.language = language
  if (smConfig) {
    if (smConfig.baseURL) axios.defaults.baseURL = smConfig.baseURL
    if (smConfig.headers) axios.defaults.headers = smConfig.headers
    if (smConfig.spaceIdList) axios.defaults.headers['X-SM-SCOPE'] = smConfig.spaceIdList
    if (smConfig.idp) axios.defaults.headers['X-IDP'] = smConfig.idp
    if (smConfig.contentUrl) axios.contentUrl = smConfig.contentUrl
    if (smConfig.portal) axios.portal = smConfig.portal
  }
  // get/create the root
  if (!rootElement) {
    let widgetWrapper = document.getElementById('sm-widget-wrapper');
    if (!widgetWrapper) {
      // create the widget wrapper
      widgetWrapper = document.createElement('div');
      widgetWrapper.setAttribute('id', 'sm-widget-wrapper');
      // get the global loader wrapper
      let glWrapper = document.getElementById('global-loader__full-page-widget-container');
      if (!glWrapper) {
        // create the global loader wrapper
        glWrapper = document.createElement('div');
        glWrapper.setAttribute('id', 'global-loader__full-page-widget-container');
        glWrapper.setAttribute('data-gn', 'nohide');
        document.body.appendChild(glWrapper);
      }
      glWrapper.appendChild(widgetWrapper);
    }
    rootElement = widgetWrapper;
  }
  // create the cdc-app container
  const cdcApp = document.createElement('cdc-app');
  rootElement.appendChild(cdcApp);
  // create the root
  const root = createRoot(cdcApp);
  
  return (
    root.render(
     // <React.StrictMode>
        <ConfigProvider configMap={configMap}>
          <AnalyticsProvider>
            <LangProvider>
              <Widget />
            </LangProvider>
          </AnalyticsProvider>
        </ConfigProvider>
    //  </React.StrictMode>
    )
  )
}

export { initialize, initialize as default }
